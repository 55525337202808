/**
 * Display element on scroll
 */

import breakpoints from '../misc/breakpoints';

function showElement(element) {
  let windowHeight = $(window).height();


  $(element).each(function () {

    let anim = "fadeIn";
    if($(this).data("fadeScroll") != "") {
      anim = $(this).data("fadeScroll");
    }

    let thisPos = $(this).offset().top;
    let topOfWindow = $(window).scrollTop();


    if (topOfWindow + windowHeight - 100 > thisPos) {
      $(this).addClass(anim);
    }
  });
}

$(document).ready(function () {

  // Only in desktop : if the element in the window of browser when the page is loaded, show that element
  if (breakpoints.isDesktopLarge()) {
    showElement('[data-fade-scroll]');
  } else {
    $('[data-fade-scroll]').css('visibility', 'visible');
  }
});

$(window).scroll(function () {

  // Only in desktop : if the element in the window of browser when scrolling the page, show that element
  if (breakpoints.isDesktopLarge()) {
    showElement('[data-fade-scroll]');
  } else {
    $('[data-fade-scroll]').css('visibility', 'visible');
  }
});

